import "./ChartBar.css";

const ChartBar = (props) => {
  let barFillHeight = "0%";

  if (props.maxValue > 0) {
    barFillHeight = Math.round((props.value / props.maxValue) * 100) + "%";
  }

  const changeMonthHandler = () => {
    props.onChangeLabel(props.label);
  };

  return (
    <div className="chart-bar" onClick={changeMonthHandler} title={props.value}>
      <div className="chart-bar__inner">
        <div
          className="chart-bar__fill"
          style={{ height: barFillHeight }}
        ></div>
      </div>
      <div className="chart-bar__label" onClick={changeMonthHandler}>
        {props.label}
      </div>
      <div className="chart-bar__label" onClick={changeMonthHandler}>
        &pound;{Math.round(props.total)}
      </div>
    </div>
  );
};

export default ChartBar;
