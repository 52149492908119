import { useRef, useContext } from "react";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import classes from "./AuthForm.module.css";

const AuthForm = (props) => {
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const authCtx = useContext(AuthContext);

  const submitHandler = async (event) => {
    event.preventDefault();
    const loginData = {
      username: emailInputRef.current.value,
      password: passwordInputRef.current.value,
    };

    try {
      const res = await axios.post(
        props.rootUrl + "account/login",
        loginData
      );
      authCtx.login(emailInputRef.current.value, res.data.token, res.data.expiration);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section className={classes.auth}>
      <h1>Login</h1>
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor="email">Your Email</label>
          <input type="email" id="email" required ref={emailInputRef} />
        </div>
        <div className={classes.control}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            required
            ref={passwordInputRef}
          />
        </div>
        <div className={classes.actions}>
          <button type="submit">Login</button>
        </div>
      </form>
    </section>
  );
};

export default AuthForm;
