import Card from "../UI/Card";
import "./CategoryItem.css";

const CategoryItem = (props) => {
    return (
        <li>
            <Card className="categoru-item">
                <div className="categoru-item__description">
                    <h2>{props.title}</h2>
                    <div className="categoru-item__price">&pound;{props.total}</div>
                </div>
            </Card>
        </li>
    );
};
export default CategoryItem;