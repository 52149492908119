import {useContext, useState} from "react";
import { Fetch } from "./helpers/Fetch";
import AuthContext from "./store/auth-context";
import NewExpense from "./components/NewExpense/NewExpense";
import Expenses from "./components/Expenses/Expenses";
import useSWR from "swr";
import "./Main.css";
import Categories from "./components/Categories/Categories";

const Main = (props) => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const apiUrlYears = props.rootUrl + "years";
  const apiUrlCategories = props.rootUrl + "categories";
  const [filteredYear, setFilteredYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(0);
  const apiUrlExpenses = props.rootUrl + "expenses/" + filteredYear;

  const { data: years, error: yearsError } = useSWR(
    apiUrlYears,
    () => Fetch(token, apiUrlYears),
    { refreshInterval: 1000 }
  );

  const { data: categories, error: categoriesError } = useSWR(
        apiUrlCategories,
        () => Fetch(token, apiUrlCategories),
       { refreshInterval: 1000 }
    );

    const { data: expenses, error: expensesError } = useSWR(
        apiUrlExpenses,
        () => Fetch(token, apiUrlExpenses),
        { refreshInterval: 1000 }
    );

  if (yearsError || categoriesError || expensesError)
      return <p>Error: {expensesError.message}, {yearsError.message}, {categoriesError.message}</p>;

  if (!years || !categories || !expenses)
      return <p>Loading...</p>;

  const filterChangeHandler = (selectedYear) => {
      setFilteredYear(selectedYear);
  };

  const monthHandler = (index) => {
      setSelectedMonth(index);
  };

  const filteredExpenses = expenses.filter((expense) => {
      if (selectedMonth === 0) return expenses;
      const expenseDate = new Date(expense.expenseDate);
      return expenseDate.getMonth() + 1 === selectedMonth;
  });

  return (
    <div className="content">
        <NewExpense
            rootUrl={props.rootUrl}
            categories={categories}
        />
        <div className="container">
            <Expenses
                rootUrl={props.rootUrl}
                years={years}
                expenses={expenses}
                categories={categories}
                filterChangeHandler={filterChangeHandler}
                filteredYear={filteredYear}
                monthHandler={monthHandler}
                filteredExpenses={filteredExpenses}
                selectedMonth={selectedMonth}
            />
            <Categories
                categories={categories}
                expenses={filteredExpenses}
            />
        </div>
    </div>
  );
};
export default Main;
