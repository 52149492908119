import { useState, useContext } from "react";
import ExpenseForm from "./ExpenseForm";
import RecurrentForm from "./RecurrentForm";
import ChangePassword from "../Auth/ChangePassword";
import AuthContext from "../../store/auth-context";
import "./NewExpense.css";
import CategoriesMenu from "./CategoriesMenu";
import AddCategory from "./AddCategory";
import CategoryEdit from "./CategoryEdit";

const NewExpense = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingRecurrent, setIsEditingRecurrent] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [isMenuCategories, setIsMenuCategories] = useState(false);
  const [isAddingCategories, setIsAddingCategories] = useState(false);
  const [isEditingCategories, setIsEditingCategories] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [categoryName, setCategoryName] = useState("");
  const [showButton, setShowButton] = useState(true);
  const authCtx = useContext(AuthContext);

  const saveExpenseDataHandler = (isDataSaved) => {
    if (isDataSaved) {
      setIsEditing(false);
      setShowButton(true);
    }
  };

  const saveRecurrentHandler = (isDataSaved) => {
    if (isDataSaved) {
      setIsEditingRecurrent(false);
      setShowButton(true);
    }
  };

  const saveCategoriesHandler = (isDataSaved) => {
    if (isDataSaved) {
      setIsMenuCategories(true);
      setIsAddingCategories(false);
      setIsEditingCategories(false);
    }
  };

  const savePasswordHandler = (isDataSaved) => {
    if (isDataSaved) {
      setIsEditingPassword(false);
      setShowButton(true);
    }
  };

  const startEditingHandler = () => {
    setIsEditing(true);
    setShowButton(false);
  };

  const stopEditingHandler = () => {
    setIsEditing(false);
    setShowButton(true);
  };

  const startEditingRecurrentHandler = () => {
    setIsEditingRecurrent(true);
    setShowButton(false);
  };

  const stopEditingRecurrentHandler = () => {
    setIsEditingRecurrent(false);
    setShowButton(true);
  };

  const StartCategoriesHandler = () => {
    setIsMenuCategories(true);
    setShowButton(false);
  };

  const stopCategoriesHandler = () => {
    setIsMenuCategories(false);
    setShowButton(true);
  };

  const StartAddingCategoriesHandler = () => {
    setIsAddingCategories(true);
    setIsMenuCategories(false);
    setShowButton(false);
  };

  const stopAddingCategoriesHandler = () => {
    setIsAddingCategories(false);
    setIsMenuCategories(true);
    setShowButton(false);
  };

  const StartEditingCategoriesHandler = (id, categoryName) => {
    setIsEditingCategories(true);
    setIsMenuCategories(false);
    setCategoryId(id);
    setCategoryName(categoryName);
  };

  const stopEditingCategoriesHandler = () => {
    setIsEditingCategories(false);
    setIsMenuCategories(true);
  };

  const startEditingPasswordHandler = () => {
    setIsEditingPassword(true);
    setShowButton(false);
  };

  const stopEditingPasswordHandler = () => {
    setIsEditingPassword(false);
    setShowButton(true);
  };

  const logoutHandler = () => {
    authCtx.logout();
  };

  return (
    <div className="new-expense">
      {showButton && (
        <button onClick={startEditingHandler}>New Expense</button>
      )}
      {isEditing && (
        <ExpenseForm
          onSaveExpenseData={saveExpenseDataHandler}
          onCancel={stopEditingHandler}
          rootUrl={props.rootUrl}
          categories={props.categories}
        />
      )}
      {showButton && (
        <button onClick={startEditingRecurrentHandler}>
          Recurrent Payment
        </button>
      )}
      {isEditingRecurrent && (
        <RecurrentForm
          onSaveRecurrentData={saveRecurrentHandler}
          onCancel={stopEditingRecurrentHandler}
          rootUrl={props.rootUrl}
          categories={props.categories}
        />
      )}
      {showButton && (
        <button onClick={StartCategoriesHandler}>
          Categories
        </button>
      )}
      {isMenuCategories && (
          <CategoriesMenu
              onAdding={StartAddingCategoriesHandler}
              onEditing={StartEditingCategoriesHandler}
              onCancel={stopCategoriesHandler}
              rootUrl={props.rootUrl}
              categories={props.categories}
          />
      )}
      {isAddingCategories && (
          <AddCategory
              onSaveCategoryData={saveCategoriesHandler}
              onCancel={stopAddingCategoriesHandler}
              rootUrl={props.rootUrl}
              categories={props.categories}
          />
      )}
      {isEditingCategories && (
          <CategoryEdit
              onSaveCategoryData={saveCategoriesHandler}
              onCancel={stopEditingCategoriesHandler}
              rootUrl={props.rootUrl}
              id={categoryId}
              categoryName={categoryName}
          />
      )}
      {showButton && (
        <button onClick={startEditingPasswordHandler}>
          Change Password
        </button>
      )}
      {isEditingPassword && (
        <ChangePassword
          onSavePasswordData={savePasswordHandler}
          onCancel={stopEditingPasswordHandler}
          rootUrl={props.rootUrl}
        />
      )}
      {showButton && (
        <button onClick={logoutHandler}>
          Logout
        </button>
      )}
    </div>
  );
};

export default NewExpense;
