import { useState, useContext } from "react";
import AuthContext from "../../store/auth-context";
import axios from "axios";
import "./RecurrentForm.css";

const RecurrentForm = (props) => {
  const [enteredTitle, setEnteredTitle] = useState("");
  const [enteredAmount, setEnteredAmount] = useState("");
  const [enteredStartDate, setEnteredStartDate] = useState("");
  const [enteredEndDate, setEnteredEndDate] = useState("");
  const [catId, setCatId] = useState(null);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const titleChangeHandler = (event) => {
    setEnteredTitle(event.target.value);
  };

  const amountChangeHandler = (event) => {
    setEnteredAmount(event.target.value);
  };

  const startDateChangeHandler = (event) => {
    setEnteredStartDate(event.target.value);
  };

  const endDateChangeHandler = (event) => {
    setEnteredEndDate(event.target.value);
  };

  const dropdownChangeHandler = (event) => {
    setCatId(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const expenseData = {
      description: enteredTitle,
      value: enteredAmount,
      startDate: enteredStartDate,
      endDate: enteredEndDate,
      categoryId: catId,
    };
    try {
      const res = await axios.post(
        props.rootUrl + "recurrent",
        expenseData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.expenseData);
    } catch (error) {
      console.error(error);
    }

    props.onSaveRecurrentData(true);
    setEnteredTitle("");
    setEnteredAmount("");
    setEnteredStartDate("");
    setEnteredEndDate("");
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="new-expense__controls">
        <div className="new-expense__control">
          <label>Title</label>
          <input
            type="text"
            value={enteredTitle}
            onChange={titleChangeHandler}
          />
        </div>
        <div className="new-expense__control">
          <label>Amount</label>
          <input
            type="number"
            min="0.01"
            step="0.01"
            value={enteredAmount}
            onChange={amountChangeHandler}
          />
        </div>
        <div className='new-expense__control'>
          <label>Category</label>
          <select onChange={dropdownChangeHandler}>
            <option value="">Select an option</option>
            {props.categories.map((category) => (
                <option value={category.id} key={category.id}>{category.categoryName}</option>
            ))}
          </select>
        </div>
        <div className="new-expense__controls">
          <div className="new-expense__control">
            <label>Start Date</label>
            <input
                type="date"
                value={enteredStartDate}
                onChange={startDateChangeHandler}
                required
            />
          </div>
          <div className="new-expense__control">
            <label>End Date</label>
            <input
                type="date"
                value={enteredEndDate}
                onChange={endDateChangeHandler}
                required
            />
          </div>
        </div>
      </div>
      <div className="new-expense__actions">
        <button type="button" onClick={props.onCancel}>
          Cancel
        </button>
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};

export default RecurrentForm;
