import { useState, useContext } from "react";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import "./AddCategory.css";

const AddCategory = (props) => {
	const [enteredCategory, setEnteredCategory] = useState("");
	const authCtx = useContext(AuthContext);
	const token = authCtx.token;

	const categoryChangeHandler = (event) => {
		setEnteredCategory(event.target.value);
	};

	const submitHandler = async (event) => {
		event.preventDefault();
		const categoryData = {
			categoryName: enteredCategory,
		};

		try {
			const res = await axios.post(
				props.rootUrl + "categories",
				categoryData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			console.log(res.categoryData);
		} catch (error) {
			console.error(error);
		}

		props.onSaveCategoryData(true);
		setEnteredCategory("");
	};

	return (
		<form onSubmit={submitHandler}>
			<div className="new-category__controls">
				<div className="new-category__control">
					<label>Category</label>
					<input
						type="text"
						value={enteredCategory}
						onChange={categoryChangeHandler}
						required
					/>
				</div>
			</div>
			<div className="new-category__actions">
				<button type="button" onClick={props.onCancel}>
					Cancel
				</button>
				<button type="submit">Save</button>
			</div>
		</form>
	)
};
export default AddCategory;