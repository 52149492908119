import "./CategoriesMenu.css";

const CategoriesMenu = (props) => {
    const editCategoryHandler = (event, categoryName) => {
        props.onEditing(event.target.id, categoryName);
    }

    return (
        <div>
            <div>
                <ul className="category-list">
                    {props.categories.map((category) => (
                        <li key={category.id}>
                            <h4
                                id={category.id}
                                onClick={(event) =>
                                    editCategoryHandler(event, category.categoryName)}
                                style={{ cursor: "pointer" }}>
                                    {category.categoryName}
                            </h4>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="category__actions">
                <button type="button" onClick={props.onCancel}>
                    Close
                </button>
                <button type="button" onClick={props.onAdding}>Add Category</button>
            </div>
        </div>
    );
};
export default CategoriesMenu;