import React, { useState, useEffect } from "react";

let logoutTimer;

const AuthContext = React.createContext({
  username: "",
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

const calculateTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();
  const remainingTime = adjExpirationTime - currentTime;
  return remainingTime;
};

const retrieveStoredToken = () => {
  const storedUsername = localStorage.getItem("username");
  const storedToken = localStorage.getItem("token");
  const storedExpiration = localStorage.getItem("expiration");
  const remainingTime = calculateTime(storedExpiration);

  if (remainingTime <= 60000) {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    return null;
  }
  return {
    username: storedUsername,
    token: storedToken,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  let initialUsername;
  let initialToken;

  if (tokenData) {
    initialUsername = tokenData.username;
    initialToken = tokenData.token;
  }

  const [username, setUsername] = useState(initialUsername);
  const [token, setToken] = useState(initialToken);
  const userIsLoggedIn = !!token;

  const logoutHandler = () => {
    setUsername(null);
    setToken(null);
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  };

  const loginHandler = (username, token, expirationTime) => {
    setUsername(username);
    setToken(token);
    localStorage.setItem("username", username);
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationTime);
    const remainingTime = calculateTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(()=>{
    if (tokenData){
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  },[tokenData]);

  const contextValue = {
    username: username,
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };
  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
