import React, {useContext} from "react";
import useSWR from "swr";
import { Fetch } from "../../helpers/Fetch";
import AuthContext from "../../store/auth-context";
import Card from "../UI/Card";
import ExpensesFilter from "./ExpensesFilter";
import ExpensesList from "./ExpensesList";
import ExpensesChart from "./ExpensesChart";
import Note from "../Notes/Note";
import "./Expenses.css";

const Expenses = (props) => {
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const apiUrlNotes = props.rootUrl + "notes/" + props.filteredYear;

  const { data: notes, error: notesError } = useSWR(
    apiUrlNotes,
    () => Fetch(token, apiUrlNotes),
    { refreshInterval: 1000 }
  );

  if (notesError)
    return (
      <p>
        Error: {notesError.message}
      </p>
    );
  if (!notes) return <p>Loading...</p>;

  const filterChangeHandler = (selectedYear) => {
    props.filterChangeHandler(selectedYear);
  };

  const monthHandler = (index) => {
    props.monthHandler(index);
  };

  const selectedNote = notes.find(note => note.month === props.selectedMonth);

  return (
    <div>
      <Card className="expenses">
        <ExpensesFilter
          selected={props.filteredYear}
          onChangeFilter={filterChangeHandler}
          years={props.years}
        />
        <ExpensesChart
            expenses={props.expenses}
            onMonthChange={monthHandler}
        />
        {props.selectedMonth > 0 && <Note note={selectedNote} rootUrl={props.rootUrl} />}
        <ExpensesList
            year={props.filteredYear}
            expenses={props.filteredExpenses}
            rootUrl={props.rootUrl}
            categories={props.categories}
        />
      </Card>
    </div>
  );
};

export default Expenses;
