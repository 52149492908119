import ExpenseDate from "./ExpenseDate";
import Card from "../UI/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Swal from "sweetalert2";
import { useState, useContext } from "react";
import ExpensesEdit from "./ExpensesEdit";
import AuthContext from "../../store/auth-context";
import "./ExpenseItem.css";

const ExpenseItem = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  const getCategoryNameById = () => {
    const category = props.categories.find((item) => item.id === props.categoryId);
    return category ? category.categoryName : '';
  };

  const catName = getCategoryNameById(props.categoryId);

  const removeExpense = async (id) => {
    try {
      const res = await axios.delete(
        props.rootUrl + `expenses/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemove = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        removeExpense(props.id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const editHandle = () => {
    setIsEditing(true);
  };

  const changeStatusForm = (status) => {
    setIsEditing(false);
  };
  return (
    <li>
      {!isEditing && (
        <Card className="expense-item">
          <ExpenseDate date={props.date} />
          <div className="expense-item__description">
            <h2 onDoubleClick={editHandle} style={{ cursor: "pointer" }}>
              {props.title}
            </h2>
            <p>{catName}</p>
            <div className="expense-item__price">&pound;{props.amount}</div>
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{ cursor: "pointer" }}
              onClick={handleRemove}
            />
          </div>
        </Card>
      )}
      {isEditing && (
        <Card className="expense-item">
          <ExpensesEdit
            id={props.id}
            title={props.title}
            amount={props.amount}
            date={props.date}
            categoryId={props.categoryId}
            onChangeStatusForm={changeStatusForm}
            rootUrl={props.rootUrl}
            categories={props.categories}
            catName={catName}
          />
        </Card>
      )}
    </li>
  );
};

export default ExpenseItem;
