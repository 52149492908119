import { useState, useEffect, useContext } from "react";
import axios from "axios";
import Card from "../UI/Card";
import AuthContext from "../../store/auth-context";
import "./Notes.css";

const Note = (props) => {
  const [note, setNote] = useState(props.note);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  useEffect(() => {
    setNote(props.note);
  }, [props.note]);

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const res = await axios.put(
        props.rootUrl + `notes/${note.id}`,
        note,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      alert("note saved successfully");
    } catch (error) {
      console.error(error);
    }
  };

  const textChangeHandler = (event) => {
    const noteData = {
      id: props.note.id,
      month: props.note.month,
      year: props.note.year,
      text: event.target.value,
    };
    setNote(noteData);
  };
  return (
    <Card className="note-item">
      <div className="note__controls">
        <form onSubmit={submitHandler}>
          <div className="note__control">
            <textarea value={note.text} onChange={textChangeHandler} />
          </div>
          <div className="note__actions">
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </Card>
  );
};
export default Note;
