import Chart from "../Chart/Chart";

const ExpensesChart = (props) => {
  const chartDataPoints = [
    { label: "All", value: 0, total: 0 },
    { label: "Jan", value: 0, total: 0 },
    { label: "Feb", value: 0, total: 0 },
    { label: "Mar", value: 0, total: 0 },
    { label: "Apr", value: 0, total: 0 },
    { label: "May", value: 0, total: 0 },
    { label: "Jun", value: 0, total: 0 },
    { label: "Jul", value: 0, total: 0 },
    { label: "Aug", value: 0, total: 0 },
    { label: "Sep", value: 0, total: 0 },
    { label: "Oct", value: 0, total: 0 },
    { label: "Nov", value: 0, total: 0 },
    { label: "Dec", value: 0, total: 0 },
  ];

  const expensesTransformed = [];

  for (const key in props.expenses) {
    expensesTransformed.push({
      id: props.expenses[key].id,
      expenseDate: new Date(props.expenses[key].expenseDate),
      description: props.expenses[key].description,
      value: props.expenses[key].value,
    });
  }
  for (const expense of expensesTransformed) {
    const expenseMonth = expense.expenseDate.getMonth() + 1; // starting at 1 => January => 1
    chartDataPoints[0].value += parseFloat(expense.value);
    chartDataPoints[0].total += parseFloat(expense.value);
    chartDataPoints[expenseMonth].value += parseFloat(expense.value);
    chartDataPoints[expenseMonth].total += parseFloat(expense.value);
  }
  chartDataPoints[0].value = chartDataPoints[0].value / 12;

  const indexHandler = (index) => {
    props.onMonthChange(index);
  };
 
  return <Chart dataPoints={chartDataPoints} onIndexChange={indexHandler} />;
};

export default ExpensesChart;
