import { useState, useContext } from "react";
import axios from "axios";
import AuthContext from "../../store/auth-context";
import "./ExpensesEdit.css";

const ExpensesEdit = (props) => {
  const [enteredTitle, setEnteredTitle] = useState(props.title);
  const [enteredAmount, setEnteredAmount] = useState(props.amount);
  const [enteredDate, setEnteredDate] = useState(props.date);
  const [catId, setCatId] = useState(props.categoryId);
  const isoDate = new Date(enteredDate).toISOString().substring(0, 10);
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;

  if (catId === null) {
    setCatId("");
  }

  const titleChangeHandler = (event) => {
    setEnteredTitle(event.target.value);
  };

  const amountChangeHandler = (event) => {
    setEnteredAmount(event.target.value);
  };

  const dateChangeHandler = (event) => {
    setEnteredDate(event.target.value);
  };

  const dropdownChangeHandler = (event) => {
    setCatId(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const expenseData = {
      id: props.id,
      description: enteredTitle,
      value: enteredAmount,
      expenseDate: new Date(enteredDate),
      categoryId: catId,
    };
    try {
      const res = await axios.put(
        props.rootUrl + `expenses/${props.id}`,
        expenseData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
    props.onChangeStatusForm(false);
  };
  const stopEditingHandler = () => {
    props.onChangeStatusForm(false);
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="new-expense__controls">
        <div className="new-expense__control">
          <label>Title</label>
          <input
            type="text"
            value={enteredTitle}
            onChange={titleChangeHandler}
          />
        </div>
        <div className="new-expense__control">
          <label>Amount</label>
          <input
            type="number"
            min="0.01"
            step="0.01"
            value={enteredAmount}
            onChange={amountChangeHandler}
          />
        </div>
        <div className="new-expense__control">
          <label>Date</label>
          <input type="date" value={isoDate} onChange={dateChangeHandler} />
        </div>
        <div className="new-expense__control">
          <label>Category</label>
          <select value={catId} onChange={dropdownChangeHandler}>
            <option value="">Select an option</option>
            {props.categories.map((category) => (
                <option value={category.id} key={category.id}>{category.categoryName}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="new-expense__actions">
        <button type="button" onClick={stopEditingHandler}>
          Cancel
        </button>
        <button type="submit">Save</button>
      </div>
    </form>
  );
};

export default ExpensesEdit;
