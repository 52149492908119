import "./Categories.css";
import Card from "../UI/Card";
import CategoriesList from "./CategoriesList";
import PieChart from "../Chart/PieChart";

const Categories = (props) => {
    const categoryTotals = props.expenses.reduce((totals, expense) => {
        const categoryId = expense.categoryId;
        const value = expense.value;

        if (!totals[categoryId]) {
            totals[categoryId] = 0;
        }

        totals[categoryId] += value;

        return totals;
    }, {});

    const allSum = props.expenses.reduce((sum, expense) => sum + expense.value, 0);

    const mergedCategories = props.categories.map((category) => ({
        id: category.id,
        categoryName: category.categoryName,
        total: categoryTotals[category.id] || 0,
    })).filter((category) => category.total !== 0);
    return (
        <div>
            <Card className="categories">
                <PieChart mergedCategories={mergedCategories} />
                <CategoriesList
                    allSum={allSum}
                    categoryTotals={categoryTotals}
                    categories={props.categories}
                />
            </Card>
        </div>
    )
};

export default Categories;