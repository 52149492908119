import { useState, useContext } from "react";
import AuthContext from "../../store/auth-context";
import axios from "axios";
import "./ChangePassword.css";

const ChangePassword = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [enteredConfirmPassword, setEnteredConfirmPassword] = useState("");
  const authCtx = useContext(AuthContext);
  const token = authCtx.token;
  const username = authCtx.username;

  const oldPasswordChangeHandler = (event) => {
    setOldPassword(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const confirmChangeHandler = (event) => {
    setEnteredConfirmPassword(event.target.value);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const passwordData = {
      username: username,
      oldPassword: oldPassword,
      newPassword: enteredPassword,
    };
    try {
      const res = await axios.post(
          props.rootUrl + "account/password",
          passwordData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
      );
      console.log(res.passwordData);
    } catch (error) {
      console.error(error);
    }

    props.onSavePasswordData(true);
    setOldPassword("");
    setEnteredPassword("");
    setEnteredConfirmPassword("");
  };
  return (
    <form onSubmit={submitHandler}>
      <div className="password__controls">
      <div className="password__control">
          <label>Old Password</label>
          <input
            type="password"
            value={oldPassword}
            onChange={oldPasswordChangeHandler}
          />
        </div>
        <div className="password__control">
          <label>New Password</label>
          <input
            type="password"
            value={enteredPassword}
            onChange={passwordChangeHandler}
          />
        </div>
        <div className="password__control">
          <label>Confirm Password</label>
          <input
            type="password"
            value={enteredConfirmPassword}
            onChange={confirmChangeHandler}
          />
        </div>
      </div>
      <div className="password__actions">
        <button type="button" onClick={props.onCancel}>
          Cancel
        </button>
        <button type="submit">Submit</button>
      </div>
    </form>
  );
};
export default ChangePassword;
