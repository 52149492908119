import ChartBar from "./ChartBar";
import "./Chart.css";
import { useState } from "react";

const Chart = (props) => {
  const [label, setLabel] = useState("All");
  const dataPointValues = props.dataPoints.map((dataPoint) => dataPoint.value);
  const totalMaximum = Math.max(...dataPointValues);

  const labelChangeHandler = (label) => {
    setLabel(label);
    const index = props.dataPoints.findIndex((d) => d.label === label);
    props.onIndexChange(index);
  };

  return (
    <div className="chart">
      <div>
        <h1>{label}</h1>
      </div>
      {props.dataPoints.map((dataPoint) => (
        <ChartBar
          key={dataPoint.label}
          value={dataPoint.value}
          maxValue={totalMaximum}
          label={dataPoint.label}
          total={dataPoint.total}
          onChangeLabel={labelChangeHandler}
        />
      ))}
    </div>
  );
};

export default Chart;
