import React from "react";
import { VictoryPie } from "victory";
import "./PieChart.css"

const PieChart = ({ mergedCategories }) => {
    const data = mergedCategories.map((category) => ({
        x: category.categoryName,
        y: category.total,
    }));

    return (
        <div className="piechart">
            <VictoryPie
                data={data}
                colorScale={["tomato", "orange", "gold", "cyan", "navy" ]} // Customize color scale as needed
                labels={({ datum }) => datum.x} // Use the category name as the label
                labelRadius={50} // Adjust the radius of the label placement as needed
                style={{
                    labels: {
                        fontSize: 16, // Customize the font size here
                    },
                }}
                animate={{
                    duration: 1000, // Animation duration in milliseconds
                    easing: "bounce", // Animation easing function (e.g., "linear", "ease", "ease-in-out", etc.)
                }}
            />
        </div>
    );
};

export default PieChart;
