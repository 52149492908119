import ExpenseItem from "./ExpenseItem";
import "./ExpensesList.css";

const ExpensesList = (props) => {
  if (props.expenses.length === 0) {
    return <h2 className="expenses-list__fallback">Found no expenses.</h2>;
  }

  return (
    <ul className="expenses-list">
      {props.expenses.map((expense) => (
        <ExpenseItem
          key={expense.id}
          id={expense.id}
          title={expense.description}
          amount={expense.value}
          date={expense.expenseDate}
          categoryId={expense.categoryId}
          rootUrl={props.rootUrl}
          categories={props.categories}
        />
      ))}
    </ul>
  );
};

export default ExpensesList;
