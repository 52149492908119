import './ExpensesFilter.css';

const ExpensesFilter = (props) => {
  const years = props.years;
  const dropdownChangeHandler = (event) => {
    props.onChangeFilter(event.target.value);
  };

  return (
    <div className='expenses-filter'>
      <div className='expenses-filter__control'>
        <label>Filter by year</label>
        <select value={props.selected} onChange={dropdownChangeHandler}>
        {years.map((year) => (
          <option value={year.yearDate} key={year.id}>{year.yearDate}</option>
        ))}
        </select>
      </div>
    </div>
  );
};

export default ExpensesFilter;
