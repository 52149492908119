import Main from "./Main";
import AuthForm from "./components/Auth/AuthForm";
import { useContext } from "react";
import AuthContext from "./store/auth-context";

const App = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const rootUrl = "https://apiexpenses.sites3ip.com/";

  return (
    <div>
      {isLoggedIn && <Main rootUrl={rootUrl} />}
      {!isLoggedIn && <AuthForm rootUrl={rootUrl} />}
    </div>
  );
};

export default App;
