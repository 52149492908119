import CategoryItem from "./CategoryItem";

const CategoriesList = (props) => {
    return (
        <ul className="expenses-list">
            <CategoryItem
                total={props.allSum}
                title="Total"
            />
            {props.categories.map((category) =>(
                <CategoryItem
                    key={category.id}
                    total={props.categoryTotals[category.id]}
                    title={category.categoryName}
                />
            ))}
        </ul>
    );
};
export default CategoriesList;